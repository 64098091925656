import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook10/image/imgPortadaEbook10.png"
import img1 from "@components/pageEbook/ebooks/ebook10/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook10/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook10/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook10/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook10/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook10/image/imgSilder3.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo impulsar la automatización de la fuerza de ventas con WhatsApp
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook">
      WhatsApp ha roto las barreras de comunicación que durante mucho tiempo
      existieron entre las empresas y los clientes.
      <br /> <br />
      Es por eso que WhatsApp lanzó su propia API, que busca mejorar los
      procesos de ventas de las empresas con funciones más potentes que una
      cuenta Business.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    Desde una atención multiagente hasta la posibilidad de enrutar la atención
    de llamadas a WhatsApp. La API de WhatsApp Business está repleta de varias
    capacidades avanzadas de automatización de mensajes.
    <br /> <br />
    Por eso, es importante que cuentes con esta herramienta ya que será un
    factor clave dentro de tus procesos de ventas.
  </p>
)
const textTwo = (
  <p>
    Cada consumidor moderno probablemente ha utilizado un chatbot de WhatsApp
    para conectarse con las marcas y resolver sus consultas en algún momento.
    <br /> <br />
    Gracias a los bots de WhatsApp, tu negocio puede tener una atención 24/7,
    mejorar la atención al cliente y elevar el compromiso del cliente.
  </p>
)
const textThree = (
  <p>
    Las plantillas por WhatsApp deben ser aprobadas por la misma aplicación. Por
    eso, aquí encontrarás textos que pasen más rápido este filtro.
  </p>
)

const data = {
  start: {
    support: "Ebook | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-10",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaEbook,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Importancia de la automatización de ventas.",
      },
      {
        key: 2,
        text: "Las siete mejores automatizaciones de ventas.",
      },
      {
        key: 3,
        text: "Cómo impulsar automatización de ventas con WhatsApp.",
      },
      {
        key: 4,
        text: "Importancia del chatbot en tus ventas por WhatsApp .",
      },
      {
        key: 5,
        text: "9 plantillas de mensajes de ventas para WhatsApp.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        title:
          "Cómo impulsar automatización de la fuerza de ventas con WhatsApp",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "La importancia del chatbot para WhatsApp en tus ventas",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "9 plantillas de mensajes de ventas para WhatsApp",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
}

export default data
